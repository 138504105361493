<section id="home" class="section welcome-area d-flex align-items-center">
    <div class="container">
        <div class="row align-items-center">
            <!-- Welcome Intro Start -->
            <div class="col-12 col-md-7 col-lg-6">
                <div class="welcome-intro">
                    <h1>Appo</h1>
                    <h3 class="fw-3 mt-2 mt-sm-3">Creative way to Land your page</h3>
                    <p class="my-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto beatae, asperiores tempore repudiandae saepe aspernatur unde voluptate sapiente quia ex.</p>
                    <div class="button-group">
                        <a href="#" class="btn btn-bordered"><span>Download</span></a>
                        <a href="#" class="btn btn-bordered d-none d-sm-inline-block">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-5 col-lg-6">
                <!-- Welcome Thumb -->
                <div class="welcome-thumb text-center" data-aos="fade-left" data-aos-delay="500" data-aos-duration="1000">
                    <img src="assets/img/welcome_mockup_2.png" alt="">
                </div>
                <!-- Video Icon -->
                <div class="video-icon d-none d-lg-block">
                    <a class="play-btn" data-fancybox href="https://www.youtube.com/watch?v=hs1HoLs4SD0">
                        <i class="icofont-ui-play"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>