<div class="wrapper">
    <div class="row">
        <div class="col-lg-6 col-sm-12" *ngFor="let projectIssue of projectIssues">
            <div class="image">
                <img [src]="projectIssue.url" alt="">
            </div>

            <div class="title">
                {{projectIssue.title}}
            </div>

            <div class="description">
                {{projectIssue.description}}
            </div>
        </div>
    </div>
</div>
