<div class="result-wrapper">
    <div class="single-counter px-4 py-3 text-center" *ngIf="resultNumber">
        <!-- Counter Icon -->
        <div class="counter-icon">
            <i [class]="resultNumber.icon"></i>
        </div>
        <!-- Counter Text -->
        <div class="counter-text">
            <span #counter [countUp]="resultNumber.resultNumber">&nbsp;</span> <span *ngIf="resultNumber.postFix">{{resultNumber.postFix}}</span>
            <h5>{{resultNumber.title}}</h5>
        </div>
    </div>
</div>

