<header class="section header-area">
    <div id="appo-header" class="main-header-area nav-white">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <!-- Logo -->
                <a class="navbar-brand name" routerLink="/">
                    Victoria Qian
                </a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#appo-menu">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <!-- Appo Menu -->
                <div class="collapse navbar-collapse" id="appo-menu">
                    <!-- Header Items -->
                    <ul class="navbar-nav header-items ml-auto">
                        <li class="nav-item active dropdown">
                            <a class="nav-link scroll" href="#work">
                                <span class="main-text-color">Work</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link scroll" href="#about">
                                <span class="main-text-color">About</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a target="_blank" class="nav-link" href="../../../../assets/files/Qian-Leixin-Victoria-Resume.pdf">
                                <span class="main-text-color">Resume</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="mailto:me@victoriaqian.com">
                                <span class="main-text-color">Email</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="https://www.linkedin.com/in/victoria-qian/" target="_blank">
                                <span class="main-text-color">LinkedIn</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</header>
