<footer class="section footer-area footer-bg">
    <div class="footer-bottom">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <!-- Copyright Area -->
                    <div class="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                        <!-- Copyright Left -->
                        <div class="copyright-left accent-blue">&copy; Copyrights 2021 | Victoria Qian.</div>
                        <!-- Copyright Right -->
                        <div class="copyright-right accent-blue">
                            Made with <a class="accent-blue" href="https://angular.io/">Angular</a> By <a class="accent-blue" href="#">Victoria Qian</a></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
