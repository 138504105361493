import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (prompt("Please enter access code: ") === "ciaociao") {
      return true;
    } else {
      alert("Sorry, you didn't enter a valid access code. ");
      // navigate to login page
      this.router.navigate(['/']);
      // you can save redirect url so after authing we can move them back to the page they requested
      return false;
    }
  }
}
