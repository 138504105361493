import {Component, Input, OnInit} from '@angular/core';
import {ProjectSpec} from "../../daos/project-spec";

@Component({
  selector: 'app-project-specs',
  templateUrl: './project-specs.component.html',
  styleUrls: ['./project-specs.component.scss']
})
export class ProjectSpecsComponent implements OnInit {

  @Input() projectSpecs: ProjectSpec[];

  constructor() { }

  ngOnInit(): void {
  }

}
