import { Component, OnInit } from '@angular/core';
import {ProjectThumbnail} from "../../daos/project-thumbnail";

@Component({
  selector: 'app-theme-nine',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  projectThumbnails: ProjectThumbnail[] = [
    {
      title: "ZipPals",
      category: "Product Design - iOS Mobile App",
      description: "ZipPals is a social networking app for New Yorkers. I was a Product Designer working with research data and led the product interface design.",
      imageUrl: "assets/img/zippals-mockup-thumbnail.jpg",
      pageUrl: "zippal",
    },
    {
      title: "Supstat Inc.",
      category: "UX Design - Responsive Web & Web App",
      description: "As the sole Designer, I spearheaded the evolution of new products and improving the experience of various products, including websites and LMS web app.",
      imageUrl: "assets/img/nycdsa_thumbnail.jpg",
      pageUrl: "nycdsa",
    },
    {
      title: "FIPPOA Website Redesign",
      category: "UX Design - Responsive Web",
      description: "I was a UX Consultant for the client, Fire Island Pines Property Owners Association. I conducted user research and spearheaded the redesign to improve the experience.",
      imageUrl: "assets/img/fippoa-mockup-thumbnail.jpg",
      pageUrl: "fippoa",
    }
  ];


  constructor() { }

  ngOnInit(): void {
  }

}
