<div class="features-wrapper">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-md-6 col-lg-6">
                <div class="features-text">
                    <!-- Headings -->
                    <div class="headings d-flex align-items-center mb-4" *ngIf="title">
                        <h2 class="text-capitalize">{{title}}</h2>
                    </div>
                    <p class="my-3 features-description" *ngIf="description">
                        {{description}}
                    </p>
                    <!-- Counter List -->
                    <div class="counter-list">
                        <ul>
                            <li *ngFor="let resultNumber of resultNumbers">
                                <app-result-number [resultNumber]="resultNumber"></app-result-number>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-5 offset-lg-1">
                <!-- Featured Items -->
                <div class="featured-items">
                    <ul>
                        <li *ngFor="let projectSpec of projectSpecs">
                            <!-- Single Features Item -->
                            <div class="single-features media p-2">
                                <!-- Features Title -->
                                <div class="features-title mr-3" [style.color]="projectSpec.color">
                                   <i [class]="projectSpec.icon"></i>
                                </div>
                                <!-- Features Text -->
                                <div class="features-text media-body">
                                    <h3>{{projectSpec.title}}</h3>
                                    <p>{{projectSpec.description}}</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
