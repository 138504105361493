<div class="container">
    <div class="row">
        <div class="col-12 col-sm-6 col-md-4 col-lg-3" *ngFor="let projectSpec of projectSpecs">
            <div class="benifits-item text-center p-3">
                <div class="feature-icon">
                    <i [class]="projectSpec.icon"></i>
                </div>

                <div class="benifits-text">
                    <h3 class="mb-2">
                        {{projectSpec.title}}
                    </h3>
                    <p [innerHTML]="projectSpec.description"></p>
                </div>
            </div>
        </div>
    </div>
</div>
