<div class="italy">
    <app-scrollup></app-scrollup>
    <div class="all-area">
        <app-header-two></app-header-two>

        <div class="project-sections">
            <div class="project-section">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <app-project-thumbnail [projectThumbnail]="projectThumbnail1"
                                                   [standalone]="true"
                                                   [wrapperEnabled]="false"></app-project-thumbnail>
                        </div>
                    </div>
                </div>
            </div>

            <div class="project-section">
                <app-project-highlight [text]="projectThumbnail2.title"></app-project-highlight>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                {{projectThumbnail2.description}}
                            </div>
                        </div>
                    </div>

                    <br>

                    <div class="row">
                        <div class="col-12">
                            <app-project-specs [projectSpecs]="projectSpecs"></app-project-specs>
                        </div>
                    </div>
                </div>
            </div>

            <div class="project-section">
                <app-project-highlight text="A Little More about FIPPOA"></app-project-highlight>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                Fire Island Pines Property Owners Association (FIPPOA) is a New York based NGO that has expanded
                                its responsibility from property management to now become the recognized local government operating
                                the community and protecting the ecosystem of the island. Membership is one of the main sources of
                                revenue but hasn’t seen dramatic growth recently. The client was looking for updating the current
                                website to better serve the community and potentially recruit more members.
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="project-section">
                <app-project-highlight text="Meet Warren and Jack, FIPPOA’s Primary and Secondary Users."></app-project-highlight>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                I developed these two user personas with my teammates by interviewing a mix of property owners, renters, and visitors. We started
                                by outsourcing qualified users in addition to client's user lists and collected survey responses. Instead of a clear trend between
                                property owners and renters, We noticed a difference in needs between those who had long-term ties to the island and those who had
                                relatively shorter time.
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <app-spacer height="16"></app-spacer>
                            <div class="project-image">
                                <img src="../../../assets/img/Persona%20-%20Fippoa.jpg">
                            </div>
                        </div>
                    </div>
                </div>

                <app-spacer [height]="32"></app-spacer>
            </div>

            <div class="project-section">
                <app-project-highlight text="Warren and Jack had some issues exploring FIPPOA’s current website. "></app-project-highlight>

                <div class="container">
                    <app-project-issues [projectIssues]="projectIssues"></app-project-issues>
                </div>
            </div>

            <div class="project-section">
                <app-project-highlight text="How might we help Warren feel FIPPOA members' needs are well represented while helping Jack locate useful community information?"></app-project-highlight>

                <app-spacer height="20"></app-spacer>

                <app-features [title]="featuresData.title"
                              [description]="featuresData.description"
                              [resultNumbers]="featuresData.resultNumbers"
                              [projectSpecs]="featuresData.projectSpecs"></app-features>

            </div>

            <div class="project-section">
                <app-project-highlight text="What Could we Learn from the Others?"></app-project-highlight>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                Provincetown Tourism (<a href="https://ptowntourism.com/">Link</a>) is our client's major competitor as it is an attractive getaway for New
                                Yorkers. Similar to Fire Island, Provincetown is also famous for its beautiful landscape and LGBTQ+ friendly community. I initiated a detailed
                                competitor analysis and discovered several opportunity areas to include in the FIPPOA website redesign.
                            </div>
                            <div class="project-description-bold">
                                The redesign would benefit from <span class="accent-blue">implementing a navigation menu and including intriguing images</span>, as well as
                                <span class="accent-blue">up-to-date visual assets</span> to showcase
                                the personality of the Pines.
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="project-image">
                                <img src="../../../assets/img/competitor-analysis-fippoa.jpg">
                            </div>
                        </div>
                    </div>

                <app-spacer [height]="32"></app-spacer>
            </div>

        </div>

            <div class="project-section">
                <app-project-highlight text="Simplify the Massive Sitemap into a Clear-Structure New Sitemap"></app-project-highlight>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                I collaborated with teammates and conducted both closed and open card sorting tests using Optimal Workshop. After iterations and three
                                rounds of tree tests, we achieved a simplified and intuitive new sitemap.
                            </div>
                            <div class="project-description-bold">
                                Instead of categorizing under different organization/sub-committees names, users most commonly named and grouped content into 4 categories
                                - Community, Event, Membership, and about FIPPOA.
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div>
                                <img src="../../../assets/img/sitemap-fippoa.jpg">
                            </div>
                        </div>
                    </div>
                </div>

                <app-spacer [height]="32"></app-spacer>
            </div>

            <div class="project-section">
                <app-project-highlight text="Create Versatile Reusable Building Block in the New Design"></app-project-highlight>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                I aimed to design an engaging and scalable solution for the immense useful information we received from both FIPPOA's website and
                                the booklet. I created different reusable building blocks that fit different forms of content. Not only did this reduce technical
                                complexity, but it also established a familiar pattern for the user.
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <app-spacer height="16"></app-spacer>
                            <div class="project-image">
                                <img src="../../../assets/img/reusable-fippoa.jpg">
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <app-spacer height="20"></app-spacer>
                            <div class="project-subtitle">
                                Build a Design System
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                At the beginning of the project, we had no documentation for visual and interaction design guidelines. I went the extra mile to build a
                                full design system, including a rebranded style guide, UI and interaction library that articulate dimension and important state changes.
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <app-spacer height="16"></app-spacer>
                            <div class="project-image">
                                <img src="../../../assets/img/design-system-fippoa.jpg">
                            </div>
                        </div>
                    </div>
                </div>

                <app-spacer [height]="32"></app-spacer>
            </div>

            <div class="project-section">
                <app-project-highlight text="Find all the Information you need to Know about Fire Island Pines Online."></app-project-highlight>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                My goal was to design a simple, friendly experience that would empower community members and visitors to access all the information they
                                need with minimum effort. Below is a look at the final build.
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <app-spacer height="20"></app-spacer>
                            <div class="project-subtitle">
                                Homepage
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                Unintuitive navigation with too many layers was a clear issue in the past, and I addressed this by implementing a clear-structured navigation
                                bar in the header. With a quick scroll, I crafted UI cards that allowed users to access essential community information upfront.
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <!--                    <div class="project-image-tall shadow-sm">-->
                            <!--                        <img src="../../../assets/img/homepage-fippoa.jpg">-->
                            <!--                    </div>-->

                            <div class="embed-responsive embed-responsive-4by3">
                                <iframe class="embed-responsive-item" src="https://player.vimeo.com/video/530090212?autoplay=1&loop=1&autopause=0"
                                        frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="project-subtitle">
                                Membership Page
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                FIPPOA membership is open to the public, not limited to property owners. The redesigned membership page presented a clear difference
                                among the three types of membership and included call-to-action buttons aiming for conversion. I utilized thumbnail images, illustrative
                                graphics, and icons to transform text-heavy long content into digestible sections addressing users’ concerns, for example, the value of
                                being a member.
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <!--                    <div class="project-image-tall shadow-sm">-->
                            <!--                        <img src="../../../assets/img/fippoa-membership.jpg">-->
                            <!--                    </div>-->
                            <div class="embed-responsive embed-responsive-4by3">
                                <iframe class="embed-responsive-item" src="https://player.vimeo.com/video/530089597?autoplay=1&loop=1&autopause=0"
                                        frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="project-subtitle">
                                Community Page
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                Community resources and rules shouldn’t require users to dig in to find and confuse/lose users’ interests. I aimed to reduce the effort
                                required by adding a visual directory listing available resources and guidelines. To further advertise the Pines and FIPPOA, I included
                                a photo gallery and an infographic showcasing the intertwined history of FIPPOA and the island.
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <!--                    <div class="project-image-tall shadow-sm">-->
                            <!--                        <img src="../../../assets/img/community-guideline-fippo.jpg">-->
                            <!--                    </div>-->
                            <div class="embed-responsive embed-responsive-4by3">
                                <iframe class="embed-responsive-item" src="https://player.vimeo.com/video/529632187?autoplay=1&loop=1&autopause=0"
                                        frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>

                <app-spacer [height]="32"></app-spacer>
            </div>

            <div class="project-section">
                <app-project-highlight text="“I like (that) it has a navigation bar now so I know where to go. And now I know where my membership dollar goes and see the value of it.”"></app-project-highlight>

                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-sm-12" *ngFor="let resultNumber of impactResultNumbers">
                            <app-result-number [resultNumber]="resultNumber"></app-result-number>
                        </div>
                    </div>
                </div>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                The task success rate boosted 5 times up to 97.9% while the time-on-task was reduced by 80% compared to the initial usability test on the
                                current website. The new information architecture helped reduce the bounce rate. This redesign project was well received by the client and
                                moving forward to development to launch in June 2021.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="project-section">
                <app-project-highlight text="How to Move Forward and Measure the Success?"></app-project-highlight>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                Given the 2.5 week timeline, we were only able to complete the most important three web pages.
                                However we provided our client a list of next steps that can be implemented in phases moving forward.
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                <li>Continue designing the “About” page and “Event” page based on the new sitemap</li>
                                <li>Measure session duration and compare with current data to evaluate the user engagement.</li>
                                <li>Track the conversion of the “Join Now” CTA button on the redesigned Membership page.</li>
                            </div>
                        </div>
                    </div>
                </div>

                <app-spacer [height]="36"></app-spacer>
            </div>

        <app-footer></app-footer>
    </div>
</div>
</div>