<div class="container">
    <div [ngClass]="wrapperEnabled ? 'wrapper': ''">
        <div class="row" *ngIf="projectThumbnail.imageUrl">
            <div class="col-12">
                <div class="image-wrapper" [routerLink]="projectThumbnail.pageUrl">
                    <img [src]="projectThumbnail.imageUrl">
                </div>
            </div>
        </div>

        <div class="row" *ngIf="standalone; else notStandalone">
            <div class="col-12" *ngIf="projectThumbnail.title">
                <div class="title main-text-color" [routerLink]="projectThumbnail.pageUrl">
                    {{projectThumbnail.title}}
                </div>
            </div>

            <div class="col-12" *ngIf="projectThumbnail.description">
                <div class="description main-text-color">
                    {{projectThumbnail.description}}
                </div>
            </div>
        </div>

        <ng-template #notStandalone>
            <div class="row">
                <div class="col-lg-6 col-sm-12">
                    <div class="row">
                        <div class="col-12" *ngIf="projectThumbnail.category">
                            <div class="category accent-blue">
                                {{projectThumbnail.category}}
                            </div>
                        </div>

                        <div class="col-12" *ngIf="projectThumbnail.title">
                            <div class="title main-text-color" [routerLink]="projectThumbnail.pageUrl">
                                {{projectThumbnail.title}}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-sm-12" *ngIf="projectThumbnail.description">
                    <div class="description main-text-color">
                        {{projectThumbnail.description}}
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</div>

