import {Component, Input, OnInit} from '@angular/core';
import {BasicDao} from "../../daos/basic-dao";

@Component({
  selector: 'app-project-issues',
  templateUrl: './project-issues.component.html',
  styleUrls: ['./project-issues.component.scss']
})
export class ProjectIssuesComponent implements OnInit {

  @Input() projectIssues: BasicDao[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
