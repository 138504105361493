<div class="italy">
    <app-scrollup></app-scrollup>
    <div class="all-area">
        <app-header-two></app-header-two>

        <div class="project-sections">
            <div class="project-section">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <app-project-thumbnail [projectThumbnail]="projectThumbnail1"
                                                   [standalone]="true"
                                                   [wrapperEnabled]="false"></app-project-thumbnail>
                        </div>
                    </div>
                </div>
            </div>

            <div class="project-section">
                <app-project-highlight [text]="projectThumbnail2.title"></app-project-highlight>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                {{projectThumbnail2.description}}
                            </div>
                        </div>
                    </div>

                    <br>

                    <div class="row">
                        <div class="col-12">
                            <app-project-specs [projectSpecs]="projectSpecs"></app-project-specs>
                        </div>
                    </div>
                </div>
            </div>

            <div class="project-section">
                <app-project-highlight text="About NYC Data Science Academy"></app-project-highlight>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                NYC Data Science Academy offers data-related bootcamps and online courses to both individuals and corporate clients. Currently,
                                the primary market is domestic users in the US while also targeting international users, for instance UK and China. The main website
                                advertises all programs and supports online purchase. Besides the main website, another major product of NYCDSA is a learning
                                management system for students to learn online.
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="project-image">
                                <img src="../../../assets/img/team_structure_nycdsa.png">
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="project-section">
                <app-project-highlight text="Simplifying the Online Application to Lower the Drop-Off Rate"></app-project-highlight>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                Online Application is the first step for potential candidates to begin their Data Science journey. The current workflow and the
                                interface design require an update to provide a better user experience for future students and build a consistent experience for
                                our admission team.
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <app-spacer height="16"></app-spacer>
                            <div class="project-image">
                                <img src="../../../assets/img/nycdsa-application-mock.jpg">
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="project-section">
                <app-project-highlight text="Build a better experience for future students and our admission team."></app-project-highlight>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                A decreasing number of finished applications and increasing drop-off rate first imply the need to improve the user
                                experience to better serve the end-users. There are also two business goals for this project to meet.
                                <app-spacer [height]="16"></app-spacer>
                                <div class="accent-blue">1. Generate more leads and eventually lead to more conversions.</div>
                                <div class="accent-blue">2. Bridge the gap between post-application and before contacted by the Admission team.</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="project-section">
                <app-project-highlight text="Why do the competitors’ online applications work better?"></app-project-highlight>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                I started with comparing our online application form to our major competitors’ to further research on opportunity improvement
                                fields. I went through the application process of another two Bootcamps and a Bootcamp program at a higher education institution.
                            </div>
                            <div class="project-description-bold accent-blue">
                                Finding: Our online application is too complex and has too many text fields that require users’ manual input. It also lacks a clear
                                next step after submitting the form.
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <app-spacer height="16"></app-spacer>
                            <div class="project-image">
                                <img src="../../../assets/img/nycdsa-application-comparison.jpg">
                            </div>
                        </div>
                    </div>
                </div>

                <app-spacer [height]="32"></app-spacer>
            </div>


            <div class="project-section">
                <app-project-highlight text="First thing first, what do the users say?"></app-project-highlight>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                While recruiting students through admission team members, I learned that the application form could also
                                affect the admission team’s daily job. By analyzing user behavior flow through Google Analytics and Screen
                                recordings at CrazyEgg, as well as interviewing both students and admission officers, I created primary
                                and secondary user archetypes.
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <app-spacer height="16"></app-spacer>
                            <div class="project-image">
                                <img src="../../../assets/img/nycdsa-archetypes.jpg">
                            </div>
                        </div>
                    </div>

                    <app-spacer [height]="32"></app-spacer>
                </div>

            </div>

            <div class="project-section">
                <app-project-highlight text="A simplified form with clear next steps."></app-project-highlight>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                My goal was to design a simple, mobile-friendly experience that would empower future students at their
                                first steps of becoming a data scientist and bridge the mindset gap of the next step between students
                                and admission officers.
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="embed-responsive embed-responsive-4by3">
                                <iframe class="embed-responsive-item" src="https://player.vimeo.com/video/535659404?autoplay=1&loop=1&autopause=0"
                                        frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="project-section">
                <app-project-highlight text="A better design with improved usability and refined visual design."></app-project-highlight>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                The redesign of online applications not only simplified the workflow but also updated usability issues that
                                occured in the current design. The UI improvement of these small details contributed to a significant increase
                                in task success rate and user satisfaction.
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <app-spacer height="20"></app-spacer>
                            <div class="project-subtitle">
                                Step 1: Basic Information
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                In this step, the redesign was focused on improving usability and solving current usability issues. Users cannot easily differentiate
                                an optional entry field from a required one as there is no clear color difference between the text and required marks. The new design
                                not only gives the required mark a prominent color but also lowers the opacity of the entry field to reduce distraction.
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="project-image">
                                <img src="../../../assets/img/step-1-comparison.png">
                            </div>
                            <app-spacer height="32"></app-spacer>
                        </div>
                    </div>
                </div>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="project-subtitle">
                                Stpe 2: Education & Work Experience
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                The redesign of this step had a focus on improving the user experience at the informational level. I met with the admission team to
                                learn what were the qualifiers for being admitted versus what are the good-to-have materials. By rearranging required and optional
                                information, it would help ease users’ stress and optimize the mobile experience for users.
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="project-image">
                                <img src="../../../assets/img/Step2-Before-After.png">
                            </div>
                            <app-spacer height="32"></app-spacer>
                        </div>
                    </div>
                </div>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="project-subtitle">
                                Step 3: Additional Information
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                A major flow change happened here. By removing unnecessary fields, step 3 and step 4 of the current application were combined into
                                one step. The new design also fixed existing usability issues and replaced the large text entry area with a dropdown menu to further
                                improve the usability and efficiency of the online application.
                            </div>
                        </div>
                    </div>

<!--                    <div class="row">-->
<!--                        <div class="col-12">-->
<!--                            <div class="project-image-tall shadow-sm">-->
<!--                                <img src="../../../assets/img/community-guideline-fippo.jpg">-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->

                    <div class="row">
                        <div class="col-12">
                            <div class="project-image">
                                <img src="../../../assets/img/step-3-comparison.png">
                            </div>
                            <app-spacer height="32"></app-spacer>
                        </div>
                    </div>
                </div>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="project-subtitle">
                                Application Submitted: An Engaging Next Step with Clear Emphasis
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                The new design includes an overview of the entire application process while emphasizing the next step after submitting the online
                                application. A calendar-booking function is also embedded here to gap the 20% loss on users who completed the online application but
                                were never contacted by our admission team.
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="project-image">
                                <img src="../../../assets/img/step-4-comparison.png">
                            </div>
                            <app-spacer height="32"></app-spacer>
                        </div>
                    </div>
                </div>

                <app-spacer [height]="32"></app-spacer>
            </div>

            <div class="project-section">
                <app-project-highlight text="Outcome: a simplified workflow for students and admission officers"></app-project-highlight>
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                By redesigning the online application, it not only shortened users' time spent on the form but also simplified the workflow of the
                                first 2 steps of the bootcamp application process for both students and NYCDSA admission team. I'm also anticipating that the user
                                drop-off rate will decrease, so as the 20% lead loss throughout the process on the admission side.
                            </div>
                        </div>
                    </div>
                </div>
<!--                <div class="container">-->
<!--                    <div class="row">-->
<!--                        <div class="col-lg-6 col-sm-12" *ngFor="let resultNumber of impactResultNumbers">-->
<!--                            <app-result-number [resultNumber]="resultNumber"></app-result-number>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="project-image">
                                <img src="../../../assets/img/student-comparison.jpg">
                                <img src="../../../assets/img/admission-comparison.jpg">
                            </div>
                            <app-spacer height="32"></app-spacer>
                        </div>
                    </div>
                </div>
            </div>

            <div class="project-section">
                <app-project-highlight text="Measuring the success: KPIs to look for."></app-project-highlight>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                The project is currently in staging and testing and shall move forward to production. After the launch of this new online application,
                                there are several key KPIs to look for to measure the impact of the redesign.
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                <li><b>Drop-Off Rate:</b> The redesign shall result in a decrease in drop-off rate.</li>
                                <li><b>Leads and Conversion Rate:</b> It is anticipated to see an increase in leads and a significant one in converted user numbers to move to the next step.</li>
                                <li><b>User Satisfaction:</b> The new online application shall lead to a more positive user feedback on both online experience and the transition from online to offline. </li>
                            </div>
                        </div>
                    </div>
                </div>

                <app-spacer [height]="36"></app-spacer>
            </div>


            <app-footer></app-footer>
        </div>
    </div>
</div>
