<div class="italy">
    <app-scrollup></app-scrollup>
    <div class="all-area">
        <app-header-two></app-header-two>
        <landing></landing>

        <div id="work">
            <div class="project-thumbnail-wrapper" *ngFor="let projectThumbnail of projectThumbnails">
                <app-project-thumbnail [projectThumbnail]="projectThumbnail"
                                       [standalone]="false"
                                       [wrapperEnabled]="true">
                </app-project-thumbnail>
            </div>
        </div>

        <app-about id="about"></app-about>
        <app-footer></app-footer>
    </div>
</div>
