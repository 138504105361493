import {
  AfterContentChecked,
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import {ResultNumber} from "../components/features/result-number";
import {interval} from "rxjs";

@Component({
  selector: 'app-result-number',
  templateUrl: './result-number.component.html',
  styleUrls: ['./result-number.component.scss']
})
export class ResultNumberComponent implements AfterViewInit {

  @Input() resultNumber: ResultNumber;
  @ViewChild('counter') counter: ElementRef;

  constructor() { }

  ngAfterViewInit(): void {
    if (this.counter) {
      interval(5000).subscribe(x => {
        this.counter.nativeElement.click();
      });
    }
  }

}
