<div class="italy">
    <app-scrollup></app-scrollup>
    <div class="all-area">
        <app-header-two></app-header-two>

        <div class="project-sections">
            <div class="project-section">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <app-project-thumbnail [projectThumbnail]="projectThumbnail1"
                                                   [standalone]="true"
                                                   [wrapperEnabled]="false"></app-project-thumbnail>
                        </div>
                    </div>
                </div>
            </div>

            <div class="project-section">
                <app-project-highlight [text]="projectThumbnail2.title"></app-project-highlight>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                {{projectThumbnail2.description}}
                            </div>
                        </div>
                    </div>

                    <br>

                    <div class="row">
                        <div class="col-12">
                            <app-project-specs [projectSpecs]="projectSpecs"></app-project-specs>
                        </div>
                    </div>
                </div>
            </div>

            <div class="project-section">
                <app-project-highlight text="ZipPals team and approach"></app-project-highlight>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                I worked as a Product Designer in a team of 4. I helped conduct user interviews and market research while facilitating design
                                studio sessions and leading the interface design.
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <app-spacer height="16"></app-spacer>
                            <div class="project-image">
                                <img src="../../../assets/img/zippals_team_and_process.png">
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="project-section">
                <app-project-highlight text="Why do we need a new social network app?"></app-project-highlight>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                Our team investigated the current social networking product landscape to ensure that ZipPals would hold a unique perspective.
                                We conducted research on 10 popular social network platforms, including Facebook, LinkedIn, Meetup, Tinder, etc.
                            </div>
                            <div class="project-description-bold accent-blue">
                                Opportunity: There is a lack of activity driven options to build meaningful connections outside of romantic or professional purposes.
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <app-spacer height="16"></app-spacer>
                            <div class="project-image">
                                <img src="../../../assets/img/zippals-marketing-research.png">
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="project-section">
                <app-project-highlight text="Get to know more about New Yorkers’ current experience"></app-project-highlight>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                By collecting survey data and conducting 8 user interviews, we have validated our opportunity statement. More importantly,
                                we were able to synthesize insights and finalize a user persona from 8 users’ current experiences of interacting with their
                                neighbors and community.
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <app-spacer height="16"></app-spacer>
                            <div class="project-image">
                                <img src="../../../assets/img/zippals_research_infographics.png">
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="project-section">
                <app-project-highlight text="Defining the problem statement"></app-project-highlight>
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                I started with comparing our online application form to our major competitors’ to further research on opportunity improvement
                                fields. I went through the application process of another two Bootcamps and a Bootcamp program at a higher education institution.
                            </div>
                            <div class="project-description-bold accent-blue">
                                How might we help users (Lucas) find and meet new people in the local neighborhood safely, and improve the likelihood of establishing
                                a meaningful friendship?
                            </div>
                        </div>
                    </div>
<!--                    <div class="row">-->
<!--                        <div class="col-12">-->
<!--                            <app-spacer height="16"></app-spacer>-->
<!--                            <div class="project-image">-->
<!--                                <img src="../../../assets/img/zippals_how_might_we.png">-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
                </div>
                <app-spacer [height]="32"></app-spacer>
            </div>

            <div class="project-section">
                <app-project-highlight text="Bring it all together -  Iteration of 3 key features"></app-project-highlight>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                With a defined problem and goals in mind, I led the team to move into design. ZipPals is equipped with 3 key features to target
                                users’ major pain points. With a quick iterative approach and rapid testing, these features were further developed in both
                                functionality and visual design.
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <app-spacer height="20"></app-spacer>
                            <div class="project-subtitle">
                                ID Verification
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                The ID verification feature was originally designed as a one-step verification that required an ID scan. However,  it could not
                                ensure the safety of the ZipPals community under extreme circumstances. With more research, a 2-step verification was implemented,
                                which also required users to take photos of themselves holding their IDs.
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="project-image">
                                <img src="../../../assets/img/id-iteration.png">
                            </div>
                            <app-spacer height="32"></app-spacer>
                        </div>
                    </div>
                </div>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="project-subtitle">
                                Explore Locally
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                The 1st version of the “Explore” suffered from too much information and designed interaction while the 2nd version of the mid-fi
                                wireframe was too concise. Users considered zip codes as useless information to convey walkable distance. To solve the problem and
                                increase matching efficiency, the final design included distance away and shared interests.
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="project-image">
                                <img src="../../../assets/img/explore-iteration.png">
                            </div>
                            <app-spacer height="32"></app-spacer>
                        </div>
                    </div>
                </div>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="project-subtitle">
                                Activity Generater
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                The activity was originally designed as an individual tab. Similar to the “Explore” v.1 design, it included too much information
                                that overwhelmed users. By learning that users only searched for local attractions/events when planning offline meetings, the activity
                                generator was layered in the instant messaging function.
                            </div>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-12">
                            <div class="project-image">
                                <img src="../../../assets/img/activity-iteration.png">
                            </div>
                            <app-spacer height="32"></app-spacer>
                        </div>
                    </div>
                </div>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="project-subtitle">
                                Refresh the UI
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                Users felt that the green monochromatic palette provided a feel of an insurance or finance product. With rebranding colors and
                                light heartening illustrations, users found ZipPals was more fun and matched their expectations better. The UI redesign not only
                                brought a modern feel but also addressed the color contrast issue.
                            </div>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-12">
                            <div class="project-image">
                                <img src="../../../assets/img/ui_updates.png">
                            </div>
                            <app-spacer height="32"></app-spacer>
                        </div>
                    </div>
                </div>

<!--                <app-spacer [height]="32"></app-spacer>-->
            </div>

            <div class="project-section">
                <app-project-highlight text="Introducing ZipPals, an ultimate solution for New Yorkers to connect with their community and neighborhood."></app-project-highlight>
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="embed-responsive embed-responsive-4by3">
                                <iframe class="embed-responsive-item" src="https://player.vimeo.com/video/446609158?autoplay=1&loop=1&autopause=0"
                                        frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                    <app-spacer [height]="32"></app-spacer>
                </div>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="project-subtitle">
                                Onboarding: Make users feel safe from step one
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                ZipPals started with a quick onboarding process, including easy signup, an animated one-screen feature introduction, a 2-step ID
                                verification, and concise profile building features. The onboarding was designed to be fast, easy, safe for all users from the
                                beginning.
                            </div>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-12">
                            <div class="project-image">
                                <img src="../../../assets/img/wireflow-id.png">
                            </div>
                            <app-spacer height="32"></app-spacer>
                        </div>
                    </div>
                </div>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="project-subtitle">
                                Explore: Meet locally with (almost) no effort.
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                By completing the profile building process, first-time users would arrive at the main “Explore” screen, which would be the first
                                screen for returning users, ZipPals suggested all matches having shared interests within the desired distance to make the
                                explore-and-connect process require minimum user inputs.
                            </div>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-12">
                            <div class="project-image">
                                <img src="../../../assets/img/wireflow-explore.png">
                            </div>
                            <app-spacer height="32"></app-spacer>
                        </div>
                    </div>
                </div>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="project-subtitle">
                                Connect and Meet: No more guesswork.
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                An approved connection request would lead to a change from pending status to a red notification alert to direct users to the
                                message ta. While 2 matched users planning for an offline meeting, ZipPals would provide activities based on users’ interests.
                                Confirmed offline meetings would appear under the activity tab to help remind the users.
                            </div>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-12">
                            <div class="project-image">
                                <img src="../../../assets/img/wireflow-activity-generator.png">
                            </div>
                            <app-spacer height="32"></app-spacer>
                        </div>
                    </div>
                </div>
            </div>

            <app-footer></app-footer>
        </div>
    </div>
</div>