import {Component, Input, OnInit} from '@angular/core';
import {ProjectSpec} from "../../daos/project-spec";
import {ResultNumber} from "./result-number";

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss']
})
export class FeaturesComponent implements OnInit {

  @Input() title: string;
  @Input() description: string;
  @Input() resultNumbers: ResultNumber[] = [];
  @Input() projectSpecs: ProjectSpec[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
