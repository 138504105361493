import {Component, Input, OnInit} from '@angular/core';
import {ProjectThumbnail} from "../../daos/project-thumbnail";
import {Router} from "@angular/router";

@Component({
  selector: 'app-project-thumbnail',
  templateUrl: './project-thumbnail.component.html',
  styleUrls: ['./project-thumbnail.component.scss']
})
export class ProjectThumbnailComponent implements OnInit {

  @Input() projectThumbnail: ProjectThumbnail;
  @Input() wrapperEnabled: boolean;
  @Input() standalone: boolean;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  onClick(): void {
    this.router.navigateByUrl(this.projectThumbnail.pageUrl);
  }
}
