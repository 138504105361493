<section class="section about-app-area ptb_100">
    <div class="shapes-container">
        <div class="shape-1"></div>
    </div>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-lg-6">
                <!-- About Text -->
                <div class="about-text">
                    <!-- Headings -->
                    <div class="headings d-flex align-items-center mb-4">
                        <span class="text-uppercase d-none d-sm-block">About&nbsp;Me</span>
                        <h2 class="text-capitalize">Hi👋, I'm Victoria!</h2>
                    </div>
                    <div class="reason-content">
                        I’m a Product Designer with a background in photography and fine arts. I’m currently interning at NYC Data Science Academy where I’m improving their user experience and building new products through research and design iterations.
                    </div>

                    <div class="d-none d-sm-block reason-content">
                        Prior to this, I was a UX designer at Lecco Inc. I designed the company website and internal products, and gained hands-on development skills. I delivered user-centered solutions and helped clients to achieve business goals through design.
                    </div>

                    <div class="reason-title accent-blue">
                        🔥 WHY PRODUCT DESIGN?
                    </div>

                    <div class="reason-content">
                        During my time at Parsons, I turned an infographic for the iconic Diana+ film camera, my favorite toy camera, into my first interactive project. It sparked my passion for product design, which is the perfect solution to balance my creative side with my logical side. My curiosity and creativity have continuously driven me to push pixels 👩‍🎨 while my left brain 🧠 is working on synthesizing user data.
                    </div>

                    <div class="reason-title accent-blue">
                        🎨 WHEN I’M NOT WORKING…...
                    </div>

                    <div class="reason-content">
                        I’m traveling with my 📷, painting, playing the piano, learning new skills, and practicing Italian.
                    </div>

                    <div class="reason-title accent-blue">
                        ✌️LET'S CONNECT!
                    </div>

                    <div class="reason-content">
                        Please do not hesitate to contact me about possible job opportunities at <a href="mailto:me@victoriaqian.com" class="accent-blue">me@victoriaqian.com</a>.
                    </div>

                    <!-- Store Buttons -->
                    <div class="button-group store-buttons">
                        <a a target="_blank" class="nav-link" href="../../../../assets/files/Qian-Leixin-Victoria-Resume.pdf"class="btn btn-bordered">
                            <span class="accent-blue">View Resume</span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-12 col-lg-6">
                <!-- About Thumb -->
                <div class="about-thumb text-center d-none d-lg-block">
                    <img src="assets/img/about-me.jpg">
                </div>
            </div>
        </div>
    </div>
</section>
