<section id="landing" class="section welcome-area d-flex align-items-center h-100vh pt-0">
    <div class="centered-element">
        &nbsp;
    </div>

    <div class="container">
        <div class="row align-items-center justify-content-center">
            <!-- Welcome Intro Start -->
            <div class="col-12 col-md-10 col-lg-8">
                <div class="welcome-intro text-center mt-0">
                    <div class="cd-headline clip fw-3 mt-2 mt-sm-3 headline-text">
                        <div class="main-text-color">
                            Hi 👋, I'm <span class="name accent-blue">V</span>ictoria!
                        </div>
                        <app-spacer height="4"></app-spacer>

                        <div class="secondary-headline-text">
                            I'm a <span class="accent-blue">product designer</span> with fine art roots. Currently improving the
                            user experience at NYC Data Science Academy.
                        </div>

                    </div>
                    <p class="main-text-color intro">
                        My multicultural background and proficiency in 3 languages has allowed me to tell users’ stories
                        in a strategic, human-centered way.  I turn complex problems into thoughtful interfaces and engaging
                        experiences by leveraging my strong visual design expertise and my background in visual arts.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
